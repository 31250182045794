import { Box, Grid } from '@mui/material';
import { useAppSelector } from '../../state/stores/root.store';
import { StudentKeyInformation } from '../../modules/students/key-information';
import { StudentReports } from '../../modules/students/reports/reports';
import { StudentCoCurricular } from '../../modules/students/co-curricular';
import { StudentExcursion } from '../../modules/students/excursion';

export const MyStudentsPage = () => {
  const { currentStudent } = useAppSelector((state) => state.student);
  const { isAdmin, hasParent } = useAppSelector((state) => state.adminParent);

  if (isAdmin && !hasParent) {
    return null;
  }

  return (
    <Box
      bgcolor='#D3E1EF'
      border='0.5px solid #1F3263'
      p={3}
      sx={{
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
      }}
    >
      <Grid container spacing={2}>
        <Grid container item spacing={3}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            {currentStudent && <StudentKeyInformation />}
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            {currentStudent && <StudentReports />}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {currentStudent && <StudentCoCurricular />}
        </Grid>
        <Grid item xs={12}>
          {currentStudent && <StudentExcursion />}
        </Grid>
      </Grid>
    </Box>
  );
};
