import { Box, CircularProgress, Link, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../../../state/stores/root.store';
import { ContentCard } from '../../../components/layout/ContentCard';
import { Loader } from '../../../components/loader';
import { useGetReportFile, useGetReports } from './useGetReports';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export const StudentReports = () => {
  const { currentStudent } = useAppSelector((state) => state.student);
  const [currentFile, setFile] = useState<string | null>(null);
  const [uniqueKey, setUniqueKey] = useState<number>(0);

  const { reports, error } = useGetReports(String(currentStudent?.pupilId));
  const { file, isFetching } = useGetReportFile(currentFile ?? '', uniqueKey);

  const handleFileClick = (fileId: string) => {
    setFile(fileId);
    setUniqueKey((prevKey) => prevKey + 1);
  };

  const Content = () => {
    if (!reports && !error) {
      return <Loader count={10} />;
    }

    return (
      <Box maxHeight='calc(calc(100vh - 80px) / 2.6)' overflow='auto'>
        <Stack py={3} px={2} gap={1}>
          {reports?.pages?.map((report) => {
            return report?.map(
              (reportItem: {
                reportName: string;
                reportDate: string;
                fileId: string;
              }) => {
                return (
                  <Stack
                    flexDirection='row'
                    key={reportItem.reportName}
                    sx={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                      '&:last-child': {
                        borderBottom: 'none',
                      },
                      cursor: 'pointer',
                    }}
                    onClick={() => handleFileClick(reportItem?.fileId)}
                  >
                    <Stack
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='space-between'
                      flex={1}
                      pb={1}
                    >
                      <Link
                        sx={{
                          textDecoration: 'none',
                          color: 'rgba(0,0,0,1)',
                          pr: 2,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Typography fontWeight={300}>
                          {reportItem.reportName}
                        </Typography>
                        {currentFile === reportItem.fileId && isFetching && (
                          <CircularProgress
                            sx={{ maxWidth: '16px', maxHeight: '16px' }}
                          />
                        )}
                      </Link>

                      <Stack
                        flexDirection='row'
                        alignItems='center'
                        justifyContent='flex-end'
                        gap={1}
                        minWidth={100}
                      >
                        <Typography fontSize={14} fontWeight={500}>
                          {`${new Date(reportItem.reportDate).toLocaleString(
                            'default',
                            { month: 'long' },
                          )} ${new Date(reportItem.reportDate).getFullYear()}`}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              },
            );
          })}
        </Stack>
      </Box>
    );
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Reports.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  return (
    <ContentCard
      icon={<LibraryBooksIcon />}
      title='Reports'
      titleAttributes={{ 'data-tour': 'reports' }}
      content={<Content />}
    />
  );
};
