import { Box, Button, Link, Skeleton, Stack, Typography } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { InformationValue } from '../../../components/information-value';
import { ContentCard } from '../../../components/layout/ContentCard';
import { StudentAvatar } from '../../../components/student-list/student-avatar';
import { LINK } from '../../../config/link.config';
import { useAppSelector } from '../../../state/stores/root.store';
import { useGetLuminary } from '../hooks/useGetLuminary';
import { useGetSchoolTerms } from '../../../hooks/useGetSchoolTerms';
import { useSubjects } from '../subjects/useSubjects';
import { MedicalInformation } from '../medical-information';
import FeedbackIcon from '@mui/icons-material/Feedback';

export const StudentKeyInformation = () => {
  const { currentStudent } = useAppSelector((state) => state.student);
  const [showMedicalInfo, toggleMedicalInfo] = useState(false);

  const currentYear = Number(currentStudent?.yearGroup?.split(' ')[1]);
  const isSenior = typeof currentYear === 'number' && currentYear > 6;

  const { schoolTerms } = useGetSchoolTerms();
  const shouldFetchLuminary =
    schoolTerms?.suppressTeachersAndClassInformation ?? false;
  const {
    data: luminary,
    isLoading: isFetchingLuminary,
    error: errorLuminary,
  } = useGetLuminary(!shouldFetchLuminary);

  const { data, isLoading } = useSubjects();
  const dataLuminary = data?.filter((item) => item.subjectName === 'Luminary');

  const Content = useCallback(() => {
    return (
      <>
        <Box
          maxHeight='calc(calc(100vh - 30px) / 2.6)'
          overflow='auto'
          pt={2}
          pb={2}
        >
          <Stack gap={1} px={2}>
            <Stack justifyContent='center' alignItems='center'>
              <StudentAvatar
                pupilId={currentStudent?.pupilId ?? ''}
                fullName={
                  `${currentStudent?.forename} ${currentStudent?.surname}` ?? ''
                }
                acronym={currentStudent?.avatarAcronym ?? ''}
                size={56}
              />
            </Stack>
            <InformationValue
              infoName='Student Name'
              infoValue={
                `${currentStudent?.forename} ${currentStudent?.surname}` ?? ''
              }
            />
            <InformationValue
              infoName='Preferred First Name'
              infoValue={`${currentStudent?.preferredForename}` ?? ''}
            />
            <InformationValue
              infoName='Year Group'
              infoValue={currentStudent?.yearGroup ?? ''}
            />
            <InformationValue
              infoName='House'
              infoValue={currentStudent?.house ?? ''}
            />

            <InformationValue
              infoName={isSenior ? 'Luminary' : 'Teacher'}
              infoValue={
                isSenior ? (
                  <>
                    {isLoading ? (
                      <Skeleton variant='rectangular' width={100} height={10} />
                    ) : (
                      <>
                        {shouldFetchLuminary ? (
                          <Typography fontSize={14} fontWeight={600}>
                            Information available from day 1 of term 1
                          </Typography>
                        ) : (
                          <>
                            {dataLuminary?.[0]?.emailID ? (
                              <Link
                                href={`mailto:${dataLuminary?.[0]?.emailID}`}
                                target='_blank'
                              >
                                <Typography fontSize={14} fontWeight={600}>
                                  {dataLuminary?.[0]?.teacher}
                                </Typography>
                              </Link>
                            ) : (
                              '-'
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isFetchingLuminary ? (
                      <Skeleton variant='rectangular' width={100} height={10} />
                    ) : (
                      <>
                        {shouldFetchLuminary ? (
                          <Typography fontSize={14} fontWeight={600}>
                            Information available from day 1 of term 1
                          </Typography>
                        ) : (
                          <Link
                            href={`mailto:${luminary?.email}`}
                            target='_blank'
                          >
                            <Typography fontSize={14} fontWeight={600}>
                              {`${luminary?.name} ${luminary?.surname}`}
                            </Typography>
                          </Link>
                        )}
                      </>
                    )}
                  </>
                )
              }
            />

            <InformationValue
              infoName='Medical Information'
              infoAttributes={{ 'data-tour': 'medical-information' }}
            >
              <Button onClick={() => toggleMedicalInfo(true)}>
                <Typography fontWeight={300} fontSize={12}>
                  View/Edit
                </Typography>
              </Button>
            </InformationValue>

            <InformationValue
              infoName={
                <Link href={LINK.ABSENTEE_FORM} target='_blank'>
                  <Typography fontSize={14} fontWeight={500}>
                    Absentee Form
                  </Typography>
                </Link>
              }
            />
          </Stack>
        </Box>

        <MedicalInformation
          open={showMedicalInfo}
          handleClose={() => toggleMedicalInfo(false)}
        />
      </>
    );
  }, [showMedicalInfo, currentStudent, dataLuminary]);

  useEffect(() => {
    if (errorLuminary?.message) {
      toast(`Error: failed to load Luminary information.`, {
        type: 'error',
      });
    }
  }, [errorLuminary?.message]);

  return (
    <ContentCard
      icon={<FeedbackIcon />}
      title='Key Information'
      titleAttributes={{ 'data-tour': 'key-information' }}
      content={<Content />}
    />
  );
};
