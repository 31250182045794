import axios from 'axios';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import { useAppSelector } from '../../../state/stores/root.store';

export const useGetReports = (pupilId: string) => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent } = useAppSelector((state) => state.adminParent);

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: ['reports', `reports-${activeParent?.email}`, pupilId],
    enabled: !!token,
    queryFn: async ({ pageParam, signal }) => {
      const res = await axios.get(
        `${appConfig.app
          .baseUrl!}activity/getReports?page=${pageParam}&itemsperpage=1&pupilId=${pupilId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
          signal,
        },
      );
      return res.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage?.hasNextPage) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  return {
    reports: data,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};

export const useGetReportFile = (fileId: string, uniqueKey: number) => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent, isAdmin } = useAppSelector(
    (state) => state.adminParent,
  );

  const enabled = isAdmin ? !!token && !!activeParent?.email : !!token;

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['report', fileId, activeParent?.email, uniqueKey],
    enabled,
    queryFn: async () => {
      if (!fileId) {
        return null;
      }

      const response = await axios
        .get(`${appConfig.app.baseUrl!}activity/getfilebyid?fileId=${fileId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
          responseType: 'arraybuffer',
        })
        .then((responseData) => {
          const blob = new Blob([responseData.data], {
            type: 'application/pdf',
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${fileId}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return url;
        })
        .catch((err) => {
          return err;
        });
      return response;
    },
  });

  return {
    file: data,
    error,
    isFetching,
  };
};
