import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ContentCard } from '../../../components/layout/ContentCard';
import {
  formatActivityDate,
  IsMultiDayExcursion,
  formatMultiDayActivityDate,
} from '../formatDateTime';
import { displayTime12HourFormat } from '../../../utils/displayTime';
import { useGetPupils } from '../../../hooks/useGetPupils';
import { useAppDispatch } from '../../../state/stores/root.store';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { StudentAvatar } from '../../../components/student-list/student-avatar';
import { setCurrentStudent } from '../../../state/slices/student.slice';
import { PupilType } from '../../../types/students.type';
import { PATHS } from '../../../types';
import { Loader } from '../../../components/loader';
import { EmptyComingUp } from './empty-coming-up';
import { useComingUp } from './useComingUp';
import { TodoExcursionInfo } from '../todo/todo-excursion-info';
import { useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { toast } from 'react-toastify';

const ComingUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isSmallDevice } = useMediaQuery();

  const { data: timeTableInfo, error } = useComingUp();

  const { getPupilInfo } = useGetPupils();

  // State to manage selected excursion for displaying details
  const [selectedExcursion, setSelectedExcursion] = useState<number | null>(
    null,
  );

  const [isInfoOpen, setInfoOpen] = useState<boolean>(false);
  const setPermission = (
    type: 'success' | 'error' | 'default',
    currentStudent: string,
    message: string,
  ) => {
    console.log(type, currentStudent, message);
  };

  const handleSortDayPlans = (plans: any) => {
    const sortedDayPlansYearSchool = plans
      .filter(
        (pupil: { yearGroupCode: string }) =>
          pupil.yearGroupCode !== '013' && pupil.yearGroupCode !== '014',
      )
      .sort(
        (first: { yearGroup: string }, second: { yearGroup: string }) =>
          Number(second?.yearGroup?.split(' ')?.[1]) -
          Number(first?.yearGroup?.split(' ')?.[1]),
      );

    const sortedDayPlansPreYearSchool = plans
      .filter(
        (pupil: { yearGroupCode: string }) =>
          pupil.yearGroupCode === '013' || pupil.yearGroupCode === '014',
      )
      .sort(
        (first: { yearGroupCode: string }, second: { yearGroupCode: string }) =>
          Number(first?.yearGroupCode) - Number(second?.yearGroupCode),
      );

    return [...sortedDayPlansYearSchool, ...sortedDayPlansPreYearSchool];
  };

  const handleSelectStudent = (pupil: PupilType | null) => {
    dispatch(setCurrentStudent(pupil));
    navigate(PATHS.MY_STUDENTS);
  };

  const formatActivityTime = (startAt: string, endAt: string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Enable 12-hour format with am/pm
    };

    const startTime = new Date(startAt).toLocaleTimeString([], options);
    const endTime = new Date(endAt).toLocaleTimeString([], options);

    return `${startTime} - ${endTime}`;
  };

  const handleExcursionClick = (
    excursionId: number,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    // Stop event propagation to the parent TableRow
    event.stopPropagation();

    // Toggle selected excursion
    setSelectedExcursion(
      excursionId === selectedExcursion ? null : excursionId,
    );
    setInfoOpen(true);
  };

  const typographyStyle = {
    fontWeight: 'regular',
    fontSize: 13,
  };

  const tableCellHeaderStyle = {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#916d4f',
  };

  const tableCellStyle = {
    px: 0,
    py: 1,
    verticalAlign: { xs: 'top', md: 'middle' },
  };

  const Content = () => {
    if (!timeTableInfo || Object.keys(timeTableInfo).length === 0) {
      return <EmptyComingUp />;
    }

    // Collect all pupils
    const allPupils = Object.keys(timeTableInfo).map((pupilId) => ({
      ...timeTableInfo[pupilId],
      pupilInfo: getPupilInfo(pupilId),
    }));

    return (
      <Box maxHeight='calc(calc(100vh - 80px) / 2)' overflow='auto'>
        <Stack py={2} px={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='left'
                    sx={{
                      ...tableCellStyle,
                      borderBottom: 0,
                    }}
                  >
                    <Typography sx={tableCellHeaderStyle}>Student</Typography>
                  </TableCell>
                  {!isSmallDevice ? (
                    <TableCell
                      align='left'
                      sx={{
                        ...tableCellStyle,
                        borderBottom: 0,
                      }}
                    >
                      <Typography sx={tableCellHeaderStyle}>
                        Next Co-curricular Activity
                      </Typography>
                    </TableCell>
                  ) : null}
                  <TableCell
                    align='left'
                    sx={{
                      ...tableCellStyle,
                      borderBottom: 0,
                    }}
                  >
                    <Typography sx={tableCellHeaderStyle}>
                      {!isSmallDevice
                        ? 'Next Approved Excursion'
                        : 'Next Excursion / Co Curricular'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {handleSortDayPlans(allPupils)?.map((plan, index) => {
                  const { forename, surname, avatarAcronym, pupilId } =
                    plan.pupilInfo;
                  const { nextCoCurricularActivity, nextExcursion } = plan;

                  const renderCoCurricularActivity = () => (
                    <>
                      <Typography
                        sx={{
                          fontWeight: 'regular',
                          fontSize: 13,
                        }}
                      >
                        {nextCoCurricularActivity.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'regular',
                          fontSize: 13,
                        }}
                      >
                        {formatActivityTime(
                          nextCoCurricularActivity.startAt,
                          nextCoCurricularActivity.endAt,
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'regular',
                          fontSize: 13,
                        }}
                      >
                        {formatActivityDate(nextCoCurricularActivity.startAt)}
                      </Typography>
                    </>
                  );

                  const renderExcursion = () => (
                    <Stack
                      onClick={(event) =>
                        handleExcursionClick(nextExcursion.id, event)
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography sx={typographyStyle}>
                        {nextExcursion.name}
                      </Typography>
                      {IsMultiDayExcursion(
                        String(nextExcursion.runsFrom),
                        String(nextExcursion.runsTo),
                      ) ? (
                        <Typography
                          sx={typographyStyle}
                          dangerouslySetInnerHTML={{
                            __html: formatMultiDayActivityDate(
                              String(nextExcursion.runsFrom),
                              String(nextExcursion.runsTo),
                              nextExcursion.startTime,
                              nextExcursion.endTime,
                            ),
                          }}
                        />
                      ) : (
                        <Typography sx={typographyStyle}>
                          {displayTime12HourFormat(nextExcursion.startTime)}-{' '}
                          {displayTime12HourFormat(nextExcursion.endTime)},{' '}
                          {formatActivityDate(String(nextExcursion.runsFrom))}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          ...typographyStyle,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        View Excursion Info
                      </Typography>
                      {selectedExcursion === nextExcursion.id && (
                        <Stack mt={1}>
                          <TodoExcursionInfo
                            open={isInfoOpen}
                            handleClose={() => setInfoOpen(false)}
                            excursion={nextExcursion}
                            setPermission={setPermission}
                            readonly={true}
                          />
                        </Stack>
                      )}
                    </Stack>
                  );

                  return (
                    <TableRow key={index} tabIndex={0}>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          width: isSmallDevice ? '60px' : 'auto',
                          cursor: 'pointer',
                          height: '100%',
                        }}
                        onClick={() => handleSelectStudent(plan.pupilInfo)}
                      >
                        <Stack flexDirection='row' alignItems='center' gap={1}>
                          <StudentAvatar
                            pupilId={pupilId}
                            fullName={
                              forename && surname
                                ? `${forename} ${surname}`
                                : ''
                            }
                            acronym={avatarAcronym ?? ''}
                            size={36}
                          />
                          <Stack>
                            <Typography
                              sx={{
                                fontWeight: 'regular',
                                fontSize: 18,
                                color: '#1f3263',
                                width: isSmallDevice ? '100px' : 'auto',
                              }}
                            >
                              {forename}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      {!isSmallDevice ? (
                        <>
                          <TableCell sx={tableCellStyle}>
                            {nextCoCurricularActivity ? (
                              <>{renderCoCurricularActivity()}</>
                            ) : (
                              <Typography sx={typographyStyle}>
                                No co-curricular activities this term
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align='left' sx={tableCellStyle}>
                            {nextExcursion ? (
                              <>{renderExcursion()}</>
                            ) : (
                              <Typography sx={typographyStyle}>
                                No approved excursions scheduled
                              </Typography>
                            )}
                          </TableCell>
                        </>
                      ) : null}
                      {isSmallDevice ? (
                        <TableCell
                          align='left'
                          sx={{ ...tableCellStyle, verticalAlign: 'middle' }}
                        >
                          {nextCoCurricularActivity && nextExcursion ? (
                            new Date(nextCoCurricularActivity.startAt) <
                            new Date(nextExcursion.runsFrom) ? (
                              <>
                                {renderCoCurricularActivity()}
                                <Box mt={2} />
                                {renderExcursion()}
                              </>
                            ) : (
                              <>
                                {renderExcursion()}
                                <Box mt={2} />
                                {renderCoCurricularActivity()}
                              </>
                            )
                          ) : nextCoCurricularActivity ? (
                            renderCoCurricularActivity()
                          ) : nextExcursion ? (
                            renderExcursion()
                          ) : (
                            <Typography sx={typographyStyle}>
                              Nothing currently scheduled
                            </Typography>
                          )}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Box>
    );
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Coming up.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  return (
    <ContentCard
      icon={<AccessTimeIcon />}
      title='Coming up'
      titleAttributes={{ 'data-tour': 'coming-up' }}
      headerCta={
        <Button
          variant='text'
          sx={{ display: 'flex', gap: 1 }}
          onClick={() => navigate('/timetable')}
        >
          <Typography
            fontFamily='Inter, sans-serif'
            fontWeight='regular'
            fontSize={15}
            color='primary'
            textTransform='initial'
          >
            View Timetable
          </Typography>
        </Button>
      }
      content={
        !timeTableInfo && !error?.message ? <Loader count={12} /> : <Content />
      }
    />
  );
};

export { ComingUp };
