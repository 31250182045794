import {
  ReactElement,
  JSXElementConstructor,
  ReactPortal,
  useState,
} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../state/stores/root.store';
import { EventClickArg } from '@fullcalendar/core';
import { StudentAvatar } from '../../components/student-list/student-avatar';
import { Calender } from '../../components/calender';
import { useExcursionByPupilId } from '../../hooks/useExcursionByPupil';
import { useTimetableByPupilId } from '../../hooks/useTimetableByPupil';
import { useActivityByPupil } from '../../hooks/useActivityByPupil';
import { useGetSchoolTerms } from '../../hooks/useGetSchoolTerms';

function renderEventContent(eventInfo: {
  timeText:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactPortal
    | null
    | undefined;
  event: {
    title:
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | ReactPortal
      | null
      | undefined;
  };
}) {
  const eventProps = (eventInfo.event as any).extendedProps;

  const bgcolor = (type: string) => {
    switch (type) {
      case 'excursion':
        return '#b9a9bd';
      case 'term1':
      case 'term2':
      case 'term3':
      case 'term4':
        return '#99b7b7';
      default:
        return '#d9d9d9';
    }
  };

  return (
    <Stack
      flexDirection='row'
      bgcolor={bgcolor(
        eventProps.isActivity
          ? String(eventProps?.term).toLowerCase()
          : 'class',
      )}
      color={eventProps.isActivity ? '#FFFFFF' : '#000000'}
      height='100%'
      alignItems='center'
      width='100%'
      py={1}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Stack flexDirection='column' paddingX={1}>
        <Typography
          fontSize={14}
          fontWeight={500}
          sx={{
            textWrap: 'wrap',
          }}
        >
          {eventInfo.event.title}
        </Typography>
      </Stack>
    </Stack>
  );
}

export const TimetablePage = () => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)');
  const isMediumDevice = useMediaQuery(
    'only screen and (min-width : 769px) and (max-width : 992px)',
  );

  const { currentStudent } = useAppSelector((state) => state.student);

  const [activeEvent, setActiveEvent] = useState<EventClickArg | null>(null);
  const [dates, setDates] = useState<{
    startStr: string;
    endStr: string;
  } | null>(null);

  const { schoolTerms } = useGetSchoolTerms();
  const shouldFetchTimetable =
    schoolTerms?.suppressTeachersAndClassInformation ?? false;
  
  const { data: timetable } = useTimetableByPupilId({
    dates,
    pupilId: String(currentStudent?.pupilId),
    shouldFetchTimetable: !shouldFetchTimetable
  });

  const { data: activities } = useActivityByPupil({
    dates,
    pupilId: String(currentStudent?.pupilId),
  });

  const { data: excursions } = useExcursionByPupilId({
    dates,
    pupilId: String(currentStudent?.pupilId),
  });

  const StudentActivities = () => {
    const items = [];
    if (Array.isArray(timetable)) {
      items.push(...timetable);
    }

    if (Array.isArray(activities)) {
      items.push(...activities);
    }

    if (Array.isArray(excursions)) {
      items.push(...excursions);
    }

    return items;
  };

  const handleMonth = (value: { startStr: string; endStr: string }) => {
    setDates(value);
  };

  return (
    <Box
      bgcolor='#D3E1EF'
      border='0.5px solid #1F3263'
      p={3}
      sx={{
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
      }}
    >
      <Stack gap={2}>
        <Box position='relative'>
          <Calender
            isMobile={isSmallDevice || isMediumDevice}
            type='timetable'
            key={
              isSmallDevice || isMediumDevice
                ? 'calender-small'
                : 'calendar-large'
            }
            events={StudentActivities()}
            eventContent={renderEventContent}
            eventClick={(event) => setActiveEvent(event)}
            handleMonth={handleMonth}
            showClasses={shouldFetchTimetable}
          />
        </Box>
        <Dialog
          open={!!activeEvent}
          onClose={() => setActiveEvent(null)}
          sx={{
            '& .MuiPaper-root': {
              overflow: 'visible',
            },
          }}
        >
          <IconButton
            onClick={() => setActiveEvent(null)}
            sx={{
              bgcolor: '#000000',
              position: 'absolute',
              width: 24,
              height: 24,
              p: 1,
              left: -12,
              top: -12,
              color: '#FFFFFF',
              '&:hover': {
                bgcolor: '#000000',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent id='test'>
            <Stack
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
              pb={2}
            >
              <StudentAvatar
                pupilId={currentStudent?.pupilId ?? ''}
                fullName={`${currentStudent?.forename} ${currentStudent?.surname}`}
                acronym={currentStudent?.avatarAcronym ?? ''}
              />
            </Stack>
            <Stack gap={2}>
              <Stack>
                <Typography fontSize={16} fontWeight={600}>
                  {activeEvent?.event?.extendedProps?.name}
                </Typography>

                <Typography fontSize={13}>
                  {activeEvent?.event?.extendedProps.startAt &&
                    format(
                      new Date(
                        String(activeEvent?.event?.extendedProps.startAt),
                      ),
                      'EEEE dd MMMM yyyy',
                    )}
                </Typography>
                <Typography fontSize={13}>
                  {activeEvent?.event.start &&
                    format(new Date(activeEvent?.event.start), 'HH:mm')}
                  {` - `}
                  {activeEvent?.event.end &&
                    format(new Date(activeEvent?.event.end), 'HH:mm')}
                </Typography>

                <Box paddingTop={1}>
                  {activeEvent?.event.extendedProps.siteName && (
                    <Box>
                      <Typography fontSize={11} component='span'>
                        Location:
                        <Typography
                          fontSize={13}
                          fontWeight={600}
                          component='span'
                          paddingLeft={0.5}
                        >
                          {activeEvent?.event.extendedProps.siteName}
                        </Typography>
                      </Typography>
                    </Box>
                  )}
                  {activeEvent?.event.extendedProps.teacher && (
                    <Box>
                      <Typography fontSize={11} component='span'>
                        Teacher:
                      </Typography>
                      <Link
                        href={`mailto:${activeEvent?.event.extendedProps.teacherEmail}`}
                        target='_blank'
                      >
                        <Typography
                          fontSize={13}
                          fontWeight={600}
                          component='span'
                          paddingLeft={0.5}
                        >
                          {activeEvent?.event.extendedProps.teacher}
                        </Typography>
                      </Link>
                    </Box>
                  )}
                  {activeEvent?.event.extendedProps.term === 'EXCURSION' && (
                    <>
                      <Stack py={1}>
                        <Typography fontSize={12} fontWeight={700}>
                          Description
                        </Typography>
                        <Typography fontSize={12} fontWeight={300}>
                          {activeEvent?.event?.extendedProps?.description}
                        </Typography>
                      </Stack>

                      <Stack py={1}>
                        <Typography fontSize={12} fontWeight={700}>
                          What to bring
                        </Typography>
                        <Typography fontSize={12} fontWeight={300}>
                          {activeEvent?.event?.extendedProps?.whatToBring}
                        </Typography>
                      </Stack>

                      <Stack py={1}>
                        <Typography fontSize={12} fontWeight={700}>
                          Uniform
                        </Typography>
                        <Typography fontSize={12} fontWeight={300}>
                          {activeEvent?.event?.extendedProps?.uniform}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Box>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Stack>
    </Box>
  );
};
