import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import { osName } from 'react-device-detect';
import { ProfileAddresses } from '../../modules/profile/addresses';
import { ProfileRelationshipToStudents } from '../../modules/profile/relationship-to-student';
import { ProfileContactInformation } from '../../modules/profile/contact-information';
import { useTokenDecode } from '../../hooks/useTokenDecode';
import { useMsalIdToken } from '../../hooks/useMsalIdToken';
import { stringAvatar } from '../../utils/avatar';

import { UAParser } from 'ua-parser-js';
import { useProfile } from '../../modules/profile/hooks/useProfile';
import SubscribedTags from '../../modules/profile/subscribed-tags';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStudents } from '../../hooks/useStudents';

export const ProfilePage = () => {
  const token = useMsalIdToken();
  const jwtData = useTokenDecode(token ?? '');
  const tokenData = jwtData;

  const { profile, error } = useProfile();
  const { data: pupils } = useStudents();
  const acronym = `${profile?.forename?.[0]}${profile?.surname?.[0]}`;

  const parser = new UAParser();
  const { browser } = parser.getResult();

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Profile.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  return (
    <Stack justifyContent='space-between' gap={4} height='calc(100vh - 104px)'>
      <Stack gap={2}>
        <Grid>
          <Stack flexDirection='row' alignItems='center' gap={2}>
            {profile && <Avatar {...stringAvatar(acronym ?? '')} />}
            <Typography fontSize={20} fontWeight={600} data-tour='my-profile'>
              My Profile
            </Typography>
          </Stack>
        </Grid>
        <Grid container spacing={2} alignItems='stretch'>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <ProfileContactInformation
              email={
                tokenData?.alternateloginid ||
                tokenData?.email ||
                profile?.emailAddresses[0]?.email ||
                ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <ProfileRelationshipToStudents
              info={profile}
              pupils={pupils || []}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <ProfileAddresses info={profile} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <SubscribedTags />
          </Grid>
        </Grid>
      </Stack>
      <Stack py={2} bgcolor='white' mx={-3} mb={-3} px={3}>
        <Box borderBottom='1px solid' py={2} mb={2}>
          <Typography>About MLC Portal</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography fontSize={10}>
              We might ask for this information to assist you with
              troubleshooting
            </Typography>
          </Box>
          <Stack gap={1}>
            <Stack flexDirection='row'>
              <Typography minWidth='140px' fontSize={10} fontWeight={600}>
                Operating system
              </Typography>
              <Typography fontSize={10}>{osName}</Typography>
            </Stack>
            <Stack flexDirection='row'>
              <Typography minWidth='140px' fontSize={10} fontWeight={600}>
                Browser version
              </Typography>
              <Typography fontSize={10}>
                {browser?.name} {browser?.version}
              </Typography>
            </Stack>
            <Stack flexDirection='row'>
              <Typography minWidth='140px' fontSize={10} fontWeight={600}>
                Email
              </Typography>
              <Typography fontSize={10}>
                {tokenData?.alternateloginid ?? ''}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
