import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import { ContentCard } from '../../components/layout/ContentCard';
import { useCurrentTerms } from './hooks/useCurrentTerms';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

interface NextType {
  type: string;
  date: string;
  time: string;
  location: string;
  info_link: string;
}

interface UpcomingType {
  type: string;
  date: string;
  time: string;
}

interface ContactType {
  name: string;
  title: string;
  contact: string;
  email: string;
}

interface ActivityType {
  image: string;
  title: string;
  next: NextType;
  upcoming: UpcomingType[];
  contact: ContactType;
}

const NO_ACTIVITIES =
  'This student is not enrolled in any co-curricular activities this term.';

export const StudentCoCurricular = () => {
  const { data: activities, isLoading: isFetching, error } = useCurrentTerms();

  const LoadingView = () => {
    return (
      <Box position='relative' height={100}>
        <Box
          width='100%'
          height='100%'
          position='absolute'
          top={0}
          right={0}
          zIndex={10}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress size={30} />
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Co-curricular information.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  const Content = () => {
    return (
      <Box p={2}>
        {activities && activities?.length > 0 ? (
          <Stack>
            {activities?.map((activity) => (
              <Stack
                key={activity.id}
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                  py: 2,
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2}
              >
                <Stack
                  flexDirection={{ sm: 'column', lg: 'row' }}
                  gap={{ xs: 2, md: 2, lg: 0 }}
                  flex={1}
                >
                  <Stack flex={1} flexDirection='row' gap={2}>
                    <Box
                      minWidth={120}
                      height={120}
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      {activity?.image ? (
                        <img
                          src={activity?.image}
                          alt={activity?.name}
                          style={{ width: '100%', height: '100%' }}
                        />
                      ) : (
                        <img src='/icon.png' alt='MLC' />
                      )}
                    </Box>

                    <Stack flexDirection='column' width='100%'>
                      <Stack flexDirection='row' pb={2}>
                        <Typography fontSize={14} fontWeight={700}>
                          {activity?.name}
                        </Typography>
                      </Stack>

                      <Stack
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        flex={1}
                        gap={3}
                      >
                        <Stack flex={1}>
                          <Typography fontSize={12}>
                            Activity Day & Time
                          </Typography>
                          <Typography fontSize={14} fontWeight={700}>
                            {activity?.dateDescription}
                          </Typography>
                        </Stack>

                        <Stack flex={1}>
                          <Typography fontSize={12}>
                            Activity Location
                          </Typography>
                          <Typography fontSize={14} fontWeight={700}>
                            {activity?.siteName}
                          </Typography>
                        </Stack>

                        <Stack flex={1}>
                          <Typography fontSize={12}>Activity Staff</Typography>
                          {activity?.teachers?.map((teacher, index) => (
                            <Stack key={`activity-teacher-${index}`} pb={2}>
                              <Typography fontSize={14} fontWeight={600}>
                                {teacher?.name}
                              </Typography>
                              <Typography fontSize={12} fontWeight={400}>
                                {teacher?.email}
                              </Typography>
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Stack justifyContent='center' alignItems='center' py={2}>
            {!error && (
              <Typography fontWeight={600}>{NO_ACTIVITIES}</Typography>
            )}
          </Stack>
        )}
      </Box>
    );
  };

  return (
    <ContentCard
      icon={<InsertInvitationIcon />}
      title='Co-curricular'
      titleAttributes={{ 'data-tour': 'co-curricular' }}
      content={isFetching ? <LoadingView /> : <Content />}
    />
  );
};
