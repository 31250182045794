import { Grid, Stack, Typography } from '@mui/material';
import { EventClickArg } from '@fullcalendar/core';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Calender } from '../../components/calender';
import {
  ReactElement,
  JSXElementConstructor,
  ReactPortal,
  useState,
} from 'react';
import { useEventsByRange } from '../../hooks/useEventsByRange';
import { CalenderDialog } from '../../modules/calender/calender-dialog';
import { ContentDigistorm } from '../../modules/calender/calender-dialog/ContentDigistorm';
import { ContentCms } from '../../modules/calender/calender-dialog/ContentCms';

function renderEventContent(eventInfo: {
  timeText:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactPortal
    | null
    | undefined;
  event: {
    title:
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | ReactPortal
      | null
      | undefined;
  };
}) {
  const eventProps = (eventInfo.event as any).extendedProps;
  const tagColor = (eventInfo.event as any).extendedProps?.color
    ? (eventInfo.event as any).extendedProps?.color
    : '#EFF8FF';

  return (
    <Stack
      flexDirection='row'
      bgcolor={tagColor}
      color='#000000'
      height='100%'
      alignItems='center'
      width='100%'
      sx={{
        cursor: 'pointer',
      }}
    >
      <Stack flexDirection='column' p={1}>
        <Typography
          fontSize={14}
          fontWeight={400}
          sx={{
            textWrap: 'wrap',
          }}
        >
          {eventInfo.event.title}
        </Typography>
        <Typography
          fontSize={10}
          sx={{
            textWrap: 'wrap',
          }}
        >
          {eventProps.location}
        </Typography>
      </Stack>
    </Stack>
  );
}

export const CalenderPage = () => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)');
  const isMediumDevice = useMediaQuery(
    'only screen and (min-width : 769px) and (max-width : 992px)',
  );

  const [activeEvent, setActiveEvent] = useState<EventClickArg | null>(null);

  const [dates, setDates] = useState<{
    startStr: string;
    endStr: string;
  } | null>(null);

  const { data: events } = useEventsByRange({ dates });

  const handleMonth = (value: { startStr: string; endStr: string }) => {
    setDates(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Calender
          isMobile={isSmallDevice || isMediumDevice}
          key={
            isSmallDevice || isMediumDevice
              ? 'calender-small'
              : 'calendar-large'
          }
          events={events}
          eventContent={renderEventContent}
          eventClick={(event) => setActiveEvent(event)}
          handleMonth={handleMonth}
        />
      </Grid>

      <CalenderDialog
        isOpen={!!activeEvent}
        onClose={() => setActiveEvent(null)}
      >
        {activeEvent?.event?.extendedProps?.cmsEvent ? (
          <ContentCms activeEvent={activeEvent} />
        ) : (
          <ContentDigistorm activeEvent={activeEvent} />
        )}
      </CalenderDialog>
    </Grid>
  );
};
