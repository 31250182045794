import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { ContentCard } from '../../../components/layout/ContentCard';
import { useSubjects } from './useSubjects';
import { Loader } from '../../../components/loader';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

interface Props {
  shouldFetchLuminary: boolean;
}

export const Subjects = ({ shouldFetchLuminary }: Props) => {
  const { data, isLoading, error } = useSubjects();

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Subjects.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  const Content = () => {
    return (
      <>
        <Box
          maxHeight='calc(calc(100vh - 30px) / 2.6)'
          overflow='auto'
          pt={2}
          pb={2}
        >
          <Stack gap={1} px={2}>
            <Grid container item spacing={2}>
              {!error && (
                <>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography fontSize={12} fontWeight={700}>
                      SUBJECTS
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography fontSize={12} fontWeight={700}>
                      TEACHER
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid container item spacing={1}>
                {shouldFetchLuminary ? (
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography fontSize={12} fontWeight={500}>
                      Information available from day 1 of term 1
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    {data?.map((item) => (
                      <>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <Typography fontSize={12} fontWeight={700}>
                            {item?.subjectName}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <Link
                            href={`mailto:${item?.emailID}`}
                            target='_blank'
                          >
                            <Typography fontSize={12} fontWeight={500}>
                              {item?.teacher}
                            </Typography>
                          </Link>
                        </Grid>
                      </>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <ContentCard
      title='Subjects'
      titleAttributes={{ 'data-tour': 'subjects' }}
      content={isLoading ? <Loader count={10} /> : <Content />}
    />
  );
};
