export function stringAvatar(name: string, size = 30) {
  if (!name) {
    return null;
  }
  return {
    sx: {
      bgcolor: '#1F3263',
      width: size,
      height: size,
      fontSize: size / 2,
      border: '1px solid #FFFFFF',
    },
    children: name,
  };
}
