import { useEffect, useState } from 'react';
import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Loader } from '../../../../components/loader';
import { useExcursionTodo } from '../hooks/useExcursion';
import { TodoItemType } from '../../../../types/todo.types';
import TodoExcursion from '../todo-excursion';
import { ToastView } from '../../../../components/toast';
import { ContentCard } from '../../../../components/layout/ContentCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getAppConfig } from '../../../../appConfig';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { toast } from 'react-toastify';

const Excursions = () => {
  const [permission, setPermission] = useState<{
    type: 'success' | 'error' | 'default';
    currentStudent: string;
    message: string;
  } | null>(null);

  const { isSmallDevice } = useMediaQuery();

  const { todos, isFetching, error } = useExcursionTodo();

  const excursionRiskWarningLink = `${
    getAppConfig().app.baseUrlPage
  }school-information/co-curricular/procedures-and-participation/risk-warnings/`;

  const MainView = () => {
    return (
      <>
        {isFetching ? (
          <Loader count={7} sx={{ px: 0 }} />
        ) : (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Stack py={2} px={2}>
                <Typography
                  sx={{
                    fontWeight: 'regular',
                    fontSize: 13,
                  }}
                >
                  All excursions should be read in the light of associated{' '}
                  <Link
                    href={excursionRiskWarningLink}
                    target='_blank'
                    rel='noreferrer'
                    color='primary'
                  >
                    risk warnings
                  </Link>
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align='left'
                          sx={{ px: 0, py: 1, borderBottom: 0 }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#916d4f',
                            }}
                          >
                            Student
                          </Typography>
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{ px: 0, py: 1, borderBottom: 0 }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#916d4f',
                            }}
                          >
                            Excursion
                          </Typography>
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{ px: 0, py: 1, borderBottom: 0 }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#916d4f',
                            }}
                          >
                            {!isSmallDevice ? 'Date & Time' : 'When'}
                          </Typography>
                        </TableCell>
                        {!isSmallDevice ? (
                          <TableCell
                            align='center'
                            sx={{ px: 0, py: 1, borderBottom: 0 }}
                          ></TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {todos?.map(
                        (todoItem: TodoItemType, todoIndex: number) => {
                          return (
                            <TodoExcursion
                              excursion={todoItem?.activityExcursion}
                              key={`todoexcursion-${todoIndex}`}
                              setPermission={(
                                type,
                                currentStudent,
                                message,
                              ) => {
                                setPermission({
                                  type,
                                  currentStudent,
                                  message,
                                });
                              }}
                            />
                          );
                        },
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Box>

            {permission && (
              <ToastView
                isOpen={true}
                type={permission.type === 'success' ? 'success' : 'error'}
                message={permission?.message}
              />
            )}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Excursions.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  return (
    <ContentCard
      icon={<AccessTimeIcon />}
      title='Excursions'
      titleAttributes={{ 'data-tour': 'excursion' }}
      content={<MainView />}
    />
  );
};

export { Excursions };
