import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import enAU from '@fullcalendar/core/locales/en-au';

import {
  CustomContentGenerator,
  EventClickArg,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/core';
import { createRef } from 'react';
import { Stack } from '@mui/material';
import { CalendarHeader } from './calendar-header';

interface CalenderProps {
  isMobile?: boolean;
  events: EventSourceInput | undefined;
  eventContent: CustomContentGenerator<EventContentArg>;
  eventClick?: (event: EventClickArg) => void;
  dateClick?: (date: unknown) => void;
  select?: (date: unknown) => void;
  handleMonth: (value: { startStr: string; endStr: string }) => void;
  type?: 'timetable' | 'calendar';
  showClasses?: boolean;
}

export const Calender = ({
  isMobile,
  events,
  eventContent,
  eventClick,
  dateClick,
  handleMonth,
  type = 'calendar',
  showClasses,
}: CalenderProps) => {
  const calendarRef = createRef<FullCalendar>();

  return (
    <Stack>
      <CalendarHeader
        calendarRef={calendarRef}
        isMobile={isMobile}
        showClasses={showClasses}
      />
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={false}
        initialView={
          isMobile
            ? 'listWeek'
            : type === 'calendar'
            ? 'dayGridMonth'
            : 'timeGridWeek'
        }
        selectable={true}
        events={events}
        eventClick={(event) => eventClick?.(event)}
        eventContent={eventContent}
        dateClick={(date: unknown) => dateClick?.(date)}
        navLinks={true}
        height={window.innerHeight - 180}
        handleWindowResize={true}
        datesSet={(arg) => {
          handleMonth(arg);
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: true,
        }}
        locale={enAU}
        scrollTime='06:00:00'
      />
    </Stack>
  );
};
