import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import { useAppSelector } from '../../../state/stores/root.store';

export const useGetLuminary = (enabled: boolean) => {
  const token = useMsalIdToken();
  const { currentStudent } = useAppSelector((state) => state.student);
  const pupilID = String(currentStudent?.pupilId);
  const appConfig = getAppConfig();

  const { activeParent, isAdmin } = useAppSelector(
    (state) => state.adminParent,
  );

  const isEnabled =
    enabled &&
    (isAdmin
      ? !!token && !!pupilID && !!activeParent?.email
      : !!token && !!pupilID);

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['student-luminary', `student-luminary-${pupilID}`],
    enabled: isEnabled,
    queryFn: async () => {
      const res = await axios(`${appConfig.app.baseUrl!}pupil/getluminary`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'parent-email': activeParent?.email,
        },
        params: {
          pupilID,
        },
      });

      return res.data;
    },
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
