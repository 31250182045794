import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import { ContentCard } from '../../components/layout/ContentCard';
import { displayMonthDay } from '../../utils/displayMonthDay';
import { displayTime } from '../../utils/displayTime';
import { useStudentExcursion } from './hooks/useStudentExcursion';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const NO_ACTIVITIES =
  'This student does not currently have any approved excursions.';

export const StudentExcursion = () => {
  const {
    data: excursions,
    isLoading: isFetching,
    error,
  } = useStudentExcursion();

  const activityDayTime = (
    runsFrom: string,
    runsTo: string,
    description: string,
  ) => {
    const multiDay = description
      ?.split('-')
      .join(` ${String.fromCharCode(8212)} `);
    if (runsFrom === runsTo) {
      return `${displayMonthDay(String(runsFrom))} ${displayTime(
        runsTo,
      )} ${String.fromCharCode(8212)} 23:00`;
    }

    return multiDay;
  };

  const LoadingView = () => {
    return (
      <Box position='relative' height={100}>
        <Box
          width='100%'
          height='100%'
          position='absolute'
          top={0}
          right={0}
          zIndex={10}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress size={30} />
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Excursions information.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  const Content = () => {
    return (
      <Box p={2}>
        {excursions && excursions?.length > 0 ? (
          <Stack>
            {excursions?.map((excursion) => (
              <Stack
                key={excursion.id}
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2}
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                  py: 2,
                  mb: 2,
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
              >
                <Stack
                  flexDirection={{ sm: 'column', lg: 'column' }}
                  gap={{ xs: 2, md: 2, lg: 0 }}
                  flex={1}
                >
                  <Stack flexDirection='row' pb={2}>
                    <Typography fontSize={14} fontWeight={700}>
                      {excursion?.name}
                    </Typography>
                  </Stack>
                  <Stack flex={1} gap={2} pb={3}>
                    <Stack>
                      <Typography fontSize={12} fontWeight={700}>
                        Description
                      </Typography>
                      <Typography fontSize={14} fontWeight={300}>
                        {excursion?.description}
                      </Typography>
                    </Stack>

                    <Stack>
                      <Typography fontSize={12} fontWeight={700}>
                        What to bring
                      </Typography>
                      <Typography fontSize={14} fontWeight={300}>
                        {excursion?.whatToBring}
                      </Typography>
                    </Stack>

                    <Stack>
                      <Typography fontSize={12} fontWeight={700}>
                        Uniform
                      </Typography>
                      <Typography fontSize={14} fontWeight={300}>
                        {excursion?.uniform}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack pb={3}>
                    <Stack flexDirection='column' width='100%'>
                      <Stack flex={1} gap={2}>
                        <Stack>
                          <Typography fontSize={12}>
                            Activity Day & Time
                          </Typography>
                          <Typography fontSize={14} fontWeight={700}>
                            {activityDayTime(
                              excursion?.runsFrom,
                              excursion?.runsTo,
                              excursion?.dateDescription,
                            )}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography fontSize={12}>
                            Activity Location
                          </Typography>
                          <Typography fontSize={14} fontWeight={700}>
                            {excursion?.siteName}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack flex={1}>
                    <Typography fontSize={12}>Activity Staff</Typography>
                    <Stack flexDirection='row' gap={3}>
                      <Stack>
                        <Typography fontSize={14} fontWeight={600}>
                          {excursion?.nameToAppearOnReport}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Stack justifyContent='center' alignItems='center' py={2}>
            {!error && (
              <Typography fontWeight={600}>{NO_ACTIVITIES}</Typography>
            )}
          </Stack>
        )}
      </Box>
    );
  };

  return (
    <ContentCard
      icon={<AccessTimeIcon />}
      title='Upcoming Approved Excursions'
      titleAttributes={{ 'data-tour': 'upcoming-approved-excursions' }}
      content={isFetching && !error ? <LoadingView /> : <Content />}
    />
  );
};
