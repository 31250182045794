import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAppConfig } from '../appConfig';
const appConfig = getAppConfig();

export const api = createApi({
  reducerPath: 'myceetya',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl! }),
  endpoints: (build) => ({
    getSchoolTerms: build.query({
      query: ({ token }) => ({
        url: 'setting/getsettings',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getCurrentMyceetya: build.query({
      query: ({ token }) => ({
        url: 'form/getcurrentform',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useGetSchoolTermsQuery, useGetCurrentMyceetyaQuery } = api;
